<template>
	<div class="tojoy-progress">
		<span class="tojoy-progress__number">{{ number }}%</span>
		<span class="tojoy-progress__num">
              <el-progress :percentage="number" :show-text="false"></el-progress>
		</span>
	</div>
</template>

<script>
	export default {
		name: 'tojoy-progress',
		props:{
			number:{
				type: Number,
				default: 0
			}
		},
	};
</script>

<style scoped lang="scss">
	.tojoy-progress{
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		.tojoy-progress__number{
			color:#3D7EFF;
			flex: 1;
			text-align: right;
		}
		.tojoy-progress__num{
			width: 60px;
			flex-shrink: 0;
			margin-left:5px;
			/deep/ .el-progress-bar__inner{
				background-color:#3D7EFF;
			}
		}
	}
</style>
