<template>
	<div class="tojoy-month-progress">
		<h4 v-if="title">{{ title }}</h4>
		<ol class="month-progress-list">
			<li class="month-progress-list-li" v-for="(item,index) in dataList" :key="index">
				<div class="number">
					<template>
						<span v-if="type=='season'">第{{ numMap[item.orderNo] }}季度：</span>
						<span v-else>{{ item.orderNo }}月：</span>
					</template>
					<span class="amount">
						<span :class="[item.completeValue ? 'mark' : 'grey']">{{ item.completeValue || 0}}{{ unit }}</span>
						<span>/{{ item.planValue || 0 }}{{ unit }}</span>
					</span>
				</div>
				<div class="progress" v-if="item.planValue">
					<tojoy-progress :number="item.completeRate"></tojoy-progress>
				</div>
			</li>
		</ol>
	</div>
</template>

<script>
	import TojoyProgress from '../common/progress/tojoy-progress';

	export default {
		name: 'month-progress',
		components: { TojoyProgress },
		props:{
			title:{
				type: String,
				default:''
			},
			type:{
				type: String,
				default:'month'
			},
			dataList:{
				type: Array,
				default:()=>{
					return []
				}
			},
			unit:{
				type: String,
				default:''
			},
		},
		data(){
			return {
				numMap:{
					1:'一',
					2:'二',
					3:'三',
					4:'四'
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.tojoy-month-progress{
		h4{
			height: 16px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 16px;
			margin: 30px 0;
		}
		.month-progress-list{
			font-size: 12px;
			.month-progress-list-li{
				display: flex;
				align-items: center;
				/*justify-content: space-between;*/
				color:#333333;
				margin-bottom:16px;
				.number{
					min-width: 150px;
				}
				.amount{
					color:#666666;
				}
				.mark{
					font-weight: 600;
					color:#3D7EFF;
				}
				.grey{
					font-weight: 600;
					color:#666666;
				}
				.progress{
					width: 140px;
					margin-right: 40px;
				}
			}
		}
	}

</style>
