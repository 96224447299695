<template>
	<div class="tojoy-annual-summary">
		<div class="content" v-if="annualListData.length">
			<tojoy-pagination-list
				:total="total"
				:data="annualListData"
				schema="block"
				:currentPage="page"
				:size="size"
				@page-change="getPageList"
			><template #item="{ data }">
					<annual-list-item  :dataObj="data" @goAnnualDetail="goAnnualDetail"></annual-list-item>
			</template>
			</tojoy-pagination-list>
		</div>
		<Black :blankData="{type:'noAnnualTarget',isShow:true}" v-else>
			<div class="noTargetTips">
				<h4>小贴士</h4>
				<ol class="ol-list__tips">
					<li>年度目标由公司制定后下达给对应的组织</li>
					<li>年度目标由参与年度目标组织的负责人维护</li>
				</ol>
			</div>
		</Black>
	</div>
</template>

<script>
	import AnnualListItem from '../../../components/annual-target/annual-list-item';
	import TojoyPaginationList from '@/components/business/pagination-list';
	import Black from '@/components/business/blank/blank';
	import { ApiTargetYearList } from '../../../api/api_target';

	export default {
		name: 'annualSummary',
		components:{
			AnnualListItem,
			TojoyPaginationList,
			Black,
		},

		data(){
			return {
				page:1,
				size:10,
				total: 0,
				annualListData:[],
			}
		},
		mounted () {
			this.getPageList()
		},
		methods:{
			getPageList (page = this.page){
				const params={
					page,
					size:10,
				}
				ApiTargetYearList(params).then(res=>{
					if(res.code==='000000'){
						this.annualListData = res.data.list
						this.total = res.data.total
					}
				})
			},
			// 跳转年度详情
			goAnnualDetail(item){
				this.$router.push({
					path: '/mytarget/annualdetail',
					query: {
						targetId: item.id,
					}
				})
			},
		}
	};
</script>

<style scoped lang="scss">
	.tojoy-annual-summary{
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.titleTop {
			background: $white;
			padding: 27px 0 20px 30px;
			.titleWord {
				height: 18px;
				font-size: 18px;
				font-weight: 400;
				color: $lightblack;
				line-height: 18px;
			}
		}
		.content{
			flex: 1;
			height: 100%;
			overflow-y: auto;
		}
		.noTargetTips{
			margin-top: 40px;
			>h4{
				font-size: 14px;
				font-weight: 500;
				color: #333333;
				line-height: 14px;
				padding: 20px 0 10px;
			}
			.ol-list__tips{
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				line-height: 14px;
				li{
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 12px;
				}
				li::before{
					content: '';
					width: 2px;
					height: 2px;
					display: inline-block;
					background: #666666;
					margin-right: 6px;
					margin-left: 2px;
				}
			}

		}
	}

</style>
