<template>
	<div class="tojoy-annual-detail">
		<div class="tojoy-annual-detail-container">
			<!--内容-->
			<div class="content">
				<!--头部-->
				<div class="tojoyDate">
					<span class="tojoyMonth">{{ targetYearObj.year }}</span>
					<span class="tojoyYear">年</span>
				</div>
				<div class="content-left">
					<div class="content-left-title">{{ targetYearObj.name }}</div>
					<div class="content-left-header">
						<div class="header-target_desc">
							<span class="org">责任组织：</span>
							<span class="orgName">{{ targetYearObj.orgName }}</span>
							<span class="avatar target-avatar-wrap"       @click="$router.push({ name: 'homepage-navigation', query: { userId: targetYearObj.liableUserId } })"
							>
								<el-avatar v-if="targetYearObj.liableUserAvatar" size="large" :src="targetYearObj.liableUserAvatar ? targetYearObj.liableUserAvatar :defaultAvatar"></el-avatar>
								<span v-else class="target-avatar target-avatardefault">{{  targetYearObj.liableUserName? targetYearObj.liableUserName.slice(-2):'' }}</span>

							</span>
							<span class="desc_name">{{ targetYearObj.liableUserName }}</span>
						</div>
						<div class="header-target__result">
							<div class="header-target__result-box" v-for="(item,index) in targetValueList" :key="index">
								<div class="number">
									<span class="number_amount">{{ item.value || 0 }}</span>
									<span class="number_unit">{{ item.unit }}</span>
								</div>
								<div class="desc">
									{{ item.desc }}
								</div>
							</div>
						</div>
					</div>
					<div class="content-left-annual">
						<div class="content-left-annual_result">
							<span class="desc">已分配</span>
							<span class="amount">{{ assignedValue }}{{ unit }}</span>/
							<span class="desc">分配已完成</span>
							<span class="amount">{{ assignedCompleteValue }}{{ unit }}</span>/
							<span class="desc">剩余未分配</span>
							<span class="amount">{{ unAssignedValue }}{{ unit }}</span>
						</div>
						<div class="content-left-annual_list" v-if="orgDataList.length">
							<div class="list-item" v-for="(orgData,orgIndex) in orgDataList" :key="orgIndex" @click.stop.prevent="goAnnualDetail(orgData)">
								<div class="title" style="display: flex;justify-content: space-between;align-items: center">
									<span>{{orgData.name}}</span>
										<i class="iconfont iconjiantou"></i>
								</div>
								<div class="person">组织负责人：{{ orgData.liableUserName }}</div>
								<div class="desc">
									<span class="mark">{{ orgData.completeValue || 0 }}{{ orgData.unit }}/</span><span>{{ orgData.planValue }}{{ orgData.unit }}</span>
									<span class="list-item-progress">
										<tojoy-progress :number="orgData.completeRate"></tojoy-progress>
									</span>
								</div>
								<div class="list-item-month-progress">
									<template>
										<div class="flag" v-if="!orgData.show" @click.stop="hasShowData(orgData)">展开详情 <i class="el-icon-arrow-down"></i></div>
										<div class="flag" v-else @click.stop="hasShowData(orgData)">收起详情 <i class="el-icon-arrow-up"></i>
										</div>
									</template>
									<month-progress type="season" v-if="orgData.show" :dataList="orgData.quarterList" :unit="orgData.unit"></month-progress>
								</div>
							</div>
						</div>
						<div v-else class="noData">
							<Black :blankData="{type:'noAnnualTarget',isShow:true}">
								<div class="noTargetTips">
									<h4>小贴士</h4>
									<ol class="ol-list__tips">
										<li>年度目标由公司制定后下达给对应的组织 </li>
										<li>年度目标由参与年度目标组织的负责人维护</li>
									</ol>
								</div>
							</Black>
						</div>
					</div>
				</div>
				<div class="content-right">
					<div class="content-right__progress">
						<month-progress title="按月查看" :dataList="targetYearObj.monthList" :unit="targetYearObj.unit"></month-progress>
					</div>
					<div class="content-right__progress">
						<month-progress title="按季查看" type="season" :dataList="targetYearObj.quarterList" :unit="targetYearObj.unit"></month-progress>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Black from '@/components/business/blank/blank';
	import MonthProgress from '../../../components/annual-target/month-progress';
	import TojoyProgress from '../../../components/common/progress/tojoy-progress';
	import { ApiTargetAlignListById, ApiTargetYearDetail } from '../../../api/api_target';
	const defaultAvatar = require('@/assets/images/profile.png')
	import { extend } from '../../../utils';

	export default {
		name: 'annualDetail',
		components:{
			TojoyProgress,
			MonthProgress,
			Black
		},
		data(){
			return {
				defaultAvatar,
				targetId: 0,
				targetYearObj:{
					unit:'',  // 分配和指派任务单位
					name: '', // 名称
					orgName:'', // 组织名称
					liableUserAvatar: '', // 头像
					liableUserId: null, // 责任人id
					liableUserName: '', // 责任人名称
					planValue: '', // 年度计划目标
					completeValue: '', // 年度计划完成值
					completeRate: '', // 年度实际完成比例
					year:'',
					monthList: [{
						orderNo:1,
						planValue:12, // 计划目标
						completeValue: 20, // 实际目标
						completeRate: 0
					}],
					quarterList:[{
						orderNo:1,
						planValue:12, // 计划目标
						completeValue: 20, // 实际目标
						completeRate: 0
					},{
						orderNo:1,
						planValue:12, // 计划目标
						completeValue: 20, // 实际目标
						completeRate: 0
					}],
				},
				orgDataList:[],
				targetValueList:[{
					key: 0,
					desc:'目标值',
					value:null,
					unit:''
				},{
					key: 1,
					desc:'累积完成值',
					value:null,
					unit:''
				}, {
					key: 2,
					desc:'累积进度',
					value:null,
					unit:'%'
				}],
				targetYearList:[],
				assignedValue: null, // 已分配
				assignedCompleteValue:'', // 分配已完成
				unAssignedValue:'', // 分配未完成
				unit:'', // 年度目标单位
			}
		},
		mounted(){
			this.targetId = this.$route.query.targetId
			if(this.targetId){
				this.initData(this.targetId)
			}
		},
		methods:{
			initData(id){
				// 获取对齐列表
				this.getTargetAlignList(id)
				// 查询年度目标详情
				this.getTargetDetail(id)
			},
			getTargetAlignList(id){
				ApiTargetAlignListById({id}).then(res=>{
					this.orgDataList = res.data.list
					this.assignedValue = res.data.assignedValue || 0
					this.assignedCompleteValue = res.data.assignedCompleteValue || 0
					this.unAssignedValue = res.data.unAssignedValue || 0
					this.unit = res.data.unit
				})
			},
			getTargetDetail(id){
				ApiTargetYearDetail({id}).then(res=>{
					extend(this.targetYearObj, res.data)
					this.targetValueList[0].value = this.targetYearObj.planValue || 0
					this.targetValueList[1].value = this.targetYearObj.completeValue || 0
					this.targetValueList[2].value = this.targetYearObj.completeRate || 0
					this.targetValueList[0].unit = this.targetYearObj.unit
					this.targetValueList[1].unit = this.targetYearObj.unit
				})
			},
			hasShowData(item){
				this.$set(item,'show',!item.show)
			},
			goAnnualDetail(item){
				this.$router.push({
					path: '/mytarget/annualdetail',
					query: {
						targetId: item.id,
					}
				})
			},
		}
	};
</script>

<style scoped lang="scss">
	.tojoy-annual-detail{
		height: 100%;
		overflow: auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		.tojoy-annual-detail-container{
			height: 100%;
			overflow: auto;
			flex: 1;
			display: flex;
			flex-direction: column;
			.content{
				height: 100%;
				background: $white;
				margin: 20px 30px;
				display: flex;
				border-radius: 5px;
				overflow: auto;
				.tojoyDate {
					width: 50px;
					height: 50px;
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
					border-radius: 3px;
					opacity: 0.99;
					border: 1px solid #eeeeee;
					text-align: center;
					margin: 13px 20px 15px;
					text-align: center;
				}
				.tojoyYear {
					width: 29px;
					font-size: 12px;
					font-weight: 400;
					color: $grey;
				}
				.tojoyMonth {
					display: block;
					width: 100%;
					text-align: center;
					height: 16px;
					font-size: 16px;
					font-weight: 600;
					color: $lightblack;
					line-height: 16px;
					margin-top: 7px;
				}
				.content-left{
					flex:1;
					height: 100%;
					overflow: auto;
					.content-left-title{
						height: 18px;
						font-size: 18px;
						font-weight: 600;
						color: #333333;
						line-height: 18px;
						margin-top: 25px;
						margin-left:15px;
					}
					.content-left-header{
						padding: 19px 0 40px 0;
						border-bottom:1px solid #eeeeee;
					}
					.content-left-annual{
						padding-left: 15px;
						.noData{
							padding-top: 60px;
							padding-bottom: 71px;
						}
					}
				}
				.content-right{
					width: 380px;
					height: 100%;
					border-radius: 0px 5px 5px 0px;
					border: 1px solid #EEEEEE;
					padding:0 20px;
					overflow: auto;
					.content-right__progress{
						border-bottom:1px solid #eeeeee;
					}
				}
			}
		}
		.content-left-annual{
			.content-left-annual_result{
				padding: 25px 0;
				border-bottom:1px solid #eeeeee;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
				line-height: 14px;
				.amount{
					color: #333333;
					font-weight: 600;
				}
			}
			.content-left-annual_list{
				padding-left: 14px;
				.list-item{
					font-size: 12px;
					line-height: 12px;
					padding: 20px 0 0 0;
					.title{
						height: 16px;
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #333333;
						line-height: 16px;
						position: relative;
						.iconjiantou{
							margin-right: 16px;
							font-size: 12px;
							color: #CCCCCC;
						}
					}
					.title::before{
						content:'';
						position: absolute;
						width:2px;
						height:16px;
						background: #3D7EFF;
						left: -12px;
					}
					.person{
						color: #999999;
						height: 12px;
						margin-top: 15px;
					}
					.desc{
						display: flex;
						margin-top: 15px;
						.list-item-progress{
							margin-left: 20px;
						}
					}
					.list-item-month-progress{
						-moz-user-select:none; /*火狐*/
						-webkit-user-select:none; /*webkit浏览器*/
						-ms-user-select:none; /*IE10*/
						user-select:none;
						color: #666666;
						.flag{
							display: inline-block;
							margin: 20px 0 20px 0;
							cursor: pointer;
						}
					}
				}

			}
		}
		.header-target_desc{
			display: flex;
			align-items: center;
			.org{
				margin-left:15px;
			}
			.avatar{
				margin-left:40px;
				width: 40px;
				height: 40px;
				overflow: hidden;
				border-radius: 50%;
				cursor: pointer;
			}
			.desc_name{
				margin-left: 10px;
			}
		}

		.header-target__result{
			display: flex;
			align-items: center;
			margin-top: 29px;
			/*margin: 25px 0 0px 0;*/
			/*justify-content: space-evenly;*/
			.header-target__result-box{
				padding:0 50px;
				border-right: 1px solid #eeeeee;
				text-align: center;
				.number{
					text-align: left;
					.number_amount{
						height: 23px;
						font-size: 28px;
						font-weight: 500;
						color: #333333;
						line-height: 23px;
					}
					.number_unit{
						display: inline-block;
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						line-height: 14px;
						margin-left: 2px;
					}
				}
				.desc{
					text-align: left;
					height: 14px;
					font-size: 14px;
					font-weight: 400;
					color: #666666;
					line-height: 14px;
					margin-top: 10px;
				}
			}
			.header-target__result-box:last-child{
				border:0
			}
		}

		.noTargetTips{
			margin-top: 40px;
			>h4{
				font-size: 14px;
				font-weight: 500;
				color: #333333;
				line-height: 14px;
				padding: 20px 0 10px;
			}
			.ol-list__tips{
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				line-height: 14px;
				li{
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					font-size: 12px;
					font-weight: 400;
					color: #666666;
					line-height: 12px;
				}
				li::before{
					content: '';
					width: 2px;
					height: 2px;
					display: inline-block;
					background: #666666;
					margin-right: 6px;
					margin-left: 2px;
				}
			}

		}
		.mark{
			font-weight: 600;
			color: #3D7EFF;
		}
	}

</style>
