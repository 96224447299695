<template>
	<div class="tojoy-avatar-list">
		<div class="avatar-list">
			<span class="avatar" v-for="(item,index) in userList.slice(0,5)" :key="index" :class="[item.avatar ? '' : 'defalutAvatar']" :style="{width:long+'px',height:long+'px',background: item.avatar ? 'url('+item.avatar+') center center' : '',backgroundRepeat:'no-repeat',backgroundSize: 'cover', backgroundPosition:'0px 0px'}">{{ !item.avatar ?
				item.userName : ''
				}}</span>
		</div>
		<slot>
			<span class="avatar-desc">
				共{{ userList.length || 0}}人参与
			</span>
		</slot>
	</div>
</template>

<script>
	export default {
		name: 'avatar-list',
		props:{
			long:{
				type:Number,
				default:26
			},
			userList:{
				type:Array,
				default: ()=>{
					return [
						{
							"avatar": "https://profile.csdnimg.cn/7/8/4/3_panjwei",
							"userId": 1
						}
					]
				}
			},
			number:{
				type:Number,
				default:0
			}
		},
		data(){
			return {}
		},
	};
</script>

<style scoped lang="scss">
	.tojoy-avatar-list{
		display: flex;
		align-items: center;
		.avatar-list{
			position: relative;
			.avatar{
				display: inline-block;
				position: relative;
				border: 1px solid #FFFFFF;
				box-shadow: 0 0 0 1px #fff;
				border-radius: 50%;
				color: #fff;
				font-weight: 600;
				vertical-align: bottom;
				margin-right: -2px !important;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				box-sizing: content-box;
				font-weight: normal;
			}
			.defalutAvatar{
				background: #3d7eff;
				line-height: 26px;
				color: #fff;
				font-size: 12px;
			}
		}
		.avatar-desc{
			margin-left: 10px;
			font-size: 12px;
			height: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 12px;
		}

	}

</style>
