<template>
	<div class="annual-list-item__wrap"  @click="triggerDetail(dataObj)">
		<div class="annual-date">
					<span class="year">{{ dataObj.year }}</span
					><span class="unit">年</span>
		</div>
		<div class="annual-info">
			<div class="name">{{ dataObj.name }}</div>
			<div class="result">
				<span class="left">
					<span style="color:#666666">完成值/目标值：</span><span style="color:#FF7F2D">{{ dataObj.completeValue || 0 }}{{ dataObj.unit }}</span><span style="color:#333333">/{{ dataObj.planValue || 0 }}{{ dataObj.unit }}</span>
				</span>
				<span class="right">
					<tojoy-progress :number="dataObj.completeRate"></tojoy-progress>
				</span>
			</div>
			<tojoy-avatar-list  class="avatarList" :userList = "dataObj.childLiableUserList"></tojoy-avatar-list>
			<ul class="month-label month-label__wrap" v-if="dataObj.monthList">
				<li v-for="(item,index) in dataObj.monthList" :key="index" :class="[ dataObj.year< (new Date()).getFullYear() || item.orderNo <= (new Date()).getMonth()+1 ? '' :'unstart']">
					<div class="month">{{ item.orderNo }}月</div>
					<template v-if="dataObj.year< (new Date()).getFullYear() || item.orderNo < (new Date()).getMonth()+1">
						<div  class="amount" v-if="item.planValue">{{ item.completeRateStr }}</div>
						<div class="amount" v-else>
							<span>{{item.completeValue || 0}}</span><span>{{ dataObj.unit }}</span>
						</div>
					</template>
					<template v-else-if="dataObj.year== (new Date()).getFullYear() && item.orderNo==(new Date()).getMonth()+1">
						<div  class="amount" v-if="item.completeValue!==null && item.planValue">{{ item.completeRateStr }}</div>
						<div  class="amount" v-else-if="item.completeValue!==null && !item.planValue">
							<span>{{item.completeValue}}</span><span>{{ dataObj.unit }}</span>
						</div>
					</template>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import TojoyAvatarList from '@/components/annual-target/avatar-list';
	import TojoyProgress from '../common/progress/tojoy-progress';

	export default {
		name: 'annual-list-item',
		props:{
			dataObj:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		components:{
			TojoyProgress,
			TojoyAvatarList
		},
		methods:{
			triggerDetail(item){
				this.$emit('goAnnualDetail',item)
			}
		}
	};
</script>

<style scoped lang="scss">
	.annual-list-item__wrap{
		width: 100%;
		min-height: 80px;
		border-radius: 5px;
		opacity: 0.99;
		position: relative;
		.annual-date{
			width: 46px;
			height: 46px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
			border-radius: 3px;
			border: 1px solid #EEEEEE;
			position: absolute;
			top: 15px;
			left: 17px;
			text-align: center;
			.year{
				display: block;
				width: 36px;
				height: 12px;
				font-size: 16px;
				font-weight: 600;
				color: #333333;
				line-height: 12px;
				margin: 7px auto 0;
			}
			.unit{
				width: 29px;
				height: 10px;
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				line-height: 10px;
			}
		}
		.annual-info{
			margin-left: 76px;
			position: relative;
			.name{
				padding-top: 15px;
				/*height: 16px;*/
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 16px;
			}
			.result{
				line-height: 12px;
				font-size: 12px;
				font-weight: 400;
				color: #666666;
				margin-top: 15px;
				display: flex;
				align-items: center;
				.right{
					margin-left: 40px;
				}
			}
			.avatarList{
				margin-top:20px;
			}
			.month-label{
				display: flex;
				flex-wrap: wrap;
				margin: 20px 0px;
				li{
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 80px;
					height: 46px;
					background: #FFFFFF;
					border-radius: 3px;
					border: 1px solid #DDDDDD;
					margin-right: 10px;
					margin-bottom:10px;
					.amount{
						color:#3D7EFF;
						margin-top: 5px;
					}
				}
				li.unstart{
					color: #999999;
					background: #F5F5F5;
					border:none;
				}
			}
			.month-label__wrap{
				font-size: 12px;
				font-weight: 500;
				color: #333333;
				line-height: 12px;
				text-align: center;
			}
		}
	}
	.annual-list{
		background: transparent;
		flex: 1;
		height: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		z-index: 1;
		.annual-list-item{
			display: flex;
			border-bottom: none;
			background: #ffffff;
			padding: 15px 15px 0;
			margin-bottom: 10px;
			border-radius: 5px;


		}
	}

</style>
